$dark-theme-bg-color: #0e1117;
$light-theme-text-color: #31333f;
$dark-theme-text-color: #fafafa;
$secondary-bg-color: #262730;

.custom-case-title {
    margin-bottom: 25px;
}

.form-styling {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    padding: 30px;
    margin: 30px 0;
    border-radius: 15px;
    border: 1px solid $dark-theme-text-color;
    background-color: darken($dark-theme-bg-color, 10%);
}

.form-styling-light {
    background-color: $dark-theme-text-color;
    border: 1px solid $light-theme-text-color;
  }

.form-styling-light {
    border: 1px solid $dark-theme-bg-color;
}

.form-section {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.input-description {
    margin-bottom: 5px;
    font-size: 16px;
    color: inherit;
}

.my-input-custom {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    border-radius: 8px;
    color: $dark-theme-text-color;
    background-color: inherit;
    border: 1px solid $dark-theme-text-color;
    resize: none;
}

.my-input-light {
    color: $light-theme-text-color;
    background-color: $dark-theme-text-color;
    border: 1px solid $light-theme-text-color;
}

.radio-label {
    margin-right: 15px;
}

.radio-item {
    margin-right: 5px;
}

#submitbtn {
    margin-left: 0;
}

#reset-form-btn {
    margin-right: 20px;
}

.nomargin {
    margin: 0;
}

.my-h2 {
    font-size: 24px;
}

.error-input {
    border: 1px solid #cc0000;
}

.btndark {
    border: 1px solid $dark-theme-text-color;
}

.btnlight {
    color: $dark-theme-bg-color;
    border: 1px solid $dark-theme-bg-color;
}
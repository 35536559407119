$bg-color: #0e1117;
$secondary-bg-color: #262730;
$dark-theme-text-color: #fafafa;

.ant-select-dropdown {
  background: $secondary-bg-color;
  border: 1px solid #fff;
  .rc-virtual-list-holder {
    .rc-virtual-list-holder-inner {
      .ant-select-item {
        .ant-select-item-option-content {
          color: #fff;
        }
        &.ant-select-item-option-selected {
          background-color: rgba(200,200,200,0.5) !important;
          .ant-select-item-option-content {
            background-color: transparent;
          }
        }
      }
      .ant-select-item:hover {
        background-color: rgba(200,200,200,0.25) !important;
      }
    }
  }
}

.ant-select .ant-select-selection-placeholder {
  color: darken($dark-theme-text-color, 45%);
}

.light {
  background: white;
  .ant-select-dropdown {
    background: #fff;
    border: 1px solid $bg-color;
    .rc-virtual-list-holder {
      .rc-virtual-list-holder-inner {
        .ant-select-item {
          .ant-select-item-option-content {
            color: $bg-color;
          }
          &.ant-select-item-option-selected {
            background-color: rgba(200,200,200,0.75) !important;
          }
        }
        .ant-select-item:hover {
          background-color: rgba(200,200,200,0.5) !important;
        }
      }
    }
  }
}

$dark-theme-bg-color: #0e1117;
$light-theme-text-color: #31333f;
$dark-theme-text-color: #fafafa;
$secondary-bg-color: #262730;

.collapsible-container {
  margin-bottom: 1em;
}

.collapsible {
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  padding: 15px;
  width: 100%;
  border: none;
  outline: none;
  border-radius: 15px;
  color: $dark-theme-text-color;
  border: 1px solid $dark-theme-text-color;
  background-color: transparent;
  font-size: 15px;

  &:hover {
    background-color: darken($dark-theme-text-color, 85%);
  }

  .collapsible-icon {
    font-size: 1.5em;
    line-height: 1;
  }
}

.content {
  overflow: hidden;
  transition: padding 0.2s ease-out;
  // max-height: 0;
  padding-left: 15px;

  &.open {
    max-height: 100%;
    padding: 15px;
  }
}

.logo-photo {
  width: 100%;
  max-width: 500px;
  height: auto;
}

.laptop-photo {
  width: 100%;
  height: auto;
}

.mobile-photo {
  width: auto;
  height: 100%;
  max-height: 600px;
}

.top-spacer {
  padding-top: 20px;
}
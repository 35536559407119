.h1text {
  color: #fafafa;
  margin-top: 10vh;
  font-size: 50px;
}

.tagline {
  color: #83888c;
  margin-top: 20px;
  font-size: 16px;

  a {
    color: #2a6393;
  }
}

.light {
  color: #31333f;
}

.dark {
  color: #fafafa;
  a {
    color: #fafafa;
  }
}

.page-div {
  margin: 0 auto;
}

$dark-theme-bg-color: #0e1117;
$light-theme-text-color: #31333f;
$dark-theme-text-color: #fafafa;
$secondary-bg-color: #262730;

.feedbackbtn {
  width: 120px;
  margin: 0 10px;
  cursor: pointer;
  border-radius: 15px;
  height: 33px;
  background-color: transparent;
  color: $dark-theme-text-color;
  margin-bottom: 10px;
}

.feedbackbtn:hover {
  opacity: 0.8;
}

.about-title {
  margin-top: 10vh;
  font-size: 50px;
  font-weight: bold;
  margin-bottom: -22px;
}

.commonPara a {
  text-decoration: none;
}

.container {
  width: 75%;
  margin: 0 auto;
  margin-bottom: 10vh;
}

.about-description {
  margin-top: 5vh;
  margin-bottom: 5vh;
}

.about-description h1 {
  margin-bottom: 1vh;
}

.bio-wall {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 1vh;
}

.page-container {
  width: 100%;
}

.bio {
  display: flex;
  flex-direction: column;
  width: 45%;
  align-items: center;
  margin-bottom: 3vh;
}

.bio:last-child {
  margin-bottom: 0;
}

.bio:not(:last-child) {
  margin-right: auto;
}

.bio-photo {
  width: 100%;
  height: auto;
  max-height: 500px;
  border-radius: 15px;
  object-fit: cover;
  object-position: top;
}

.bio-text {
  text-align: left;
  margin-top: 1vh;
  color: $dark-theme-text-color;
}

.bio-text a {
  text-decoration: none;
}

.photo-holder {
  width: 100%;
}

.url:hover {
  color: darken($dark-theme-text-color, 20%);
}

.app-description {
  text-align: left;
  margin-top: 4vh;
  margin-bottom: 2vh;
}

.app-description:last-child {
  margin-bottom: 10vh;
}

.about-description {
  width: 100%;
}

// partner photo wall
.photo-wall {
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.row {
  display: flex;
  white-space: nowrap;
  animation: scrollRight linear infinite;
  animation-duration: 45s;
}

.row-wrapper {
  display: flex;
  white-space: nowrap;
}

.row {
  flex-shrink: 0;
}

.partner-logo {
  max-height: 95px;
  min-width: 95px;
  height: auto;
  width: auto;
  object-fit: contain;
  margin-right: 20px;
  background-color: white;
}

@keyframes scrollRight {
  0% { transform: translateX(0%); }
  100% { transform: translateX(-100%); }
}

.photo-wall::-webkit-scrollbar {
  display: none;
}

.photo-wall img {
  padding: 15px;
}

.app-description p,
.app-description h1,
.app-description h2,
.app-description h3,
.app-description ul {
  margin-bottom: 10px;
}

.app-description ul {
  margin-left: 40px;
}

.logo-bio {
  display: flex;
  flex-direction: column;
  width: 33%;
  align-items: center;
  margin-bottom: 3vh;
  margin: auto;
  padding: 10px;
}

.logo-holder img {
  max-height: 250px;
  max-width: 230px;
  border-radius: 0;
  padding: 20px;
  border-radius: 15px;
  background-color: white;
}

// collapsable bio
.collapsible-bio-container {
  margin-bottom: -30px;
}

.collapsible-bio-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 5px 15px;
  margin: 15px 0;
  border-radius: 15px;
  background-color: transparent;
  border: 1px solid $dark-theme-text-color;

  &:hover {
    background-color: darken($dark-theme-text-color, 80%);
  }
}

.collapsible-bio-name {
  color: $dark-theme-text-color;
  text-decoration: none;
}

.collapsible-bio-icon {
  font-size: 1.2em;
  line-height: 1;
}

.collapsible-bio-content {
  overflow: hidden;
  padding-left: 15px;
  transition: max-height 0.25s ease-out;
  max-height: 0;
  background-color: transparent;

  &.open {
    max-height: 100%;
    max-height: 500px;
  }
}

.linkedin-logo {
  margin-left: 10px;

  &:hover {
    color: darken($dark-theme-text-color, 30%);
  }

  .fa-linkedin { // Font Awesome specific styling
    font-size: 1.5rem; // Adjust as necessary
  }
}

// Small screen media UI
@media (max-width: 768px) {
  .container {
    width: 95%;
  }

  .bio-wall {
    flex-direction: column;
    align-items: center;
  }

  .bio {
    width: 100%;
    align-items: center;
  }

  .bio-photo {
    width: 100%;
    height: auto;
  }

  .photo-holder {
    width: 65%;
    display: flex;
    justify-content: center;
  }

  .bio-text {
    text-align: center;
  }

  .photo-wall {
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none; // Hide scrollbar for Chrome, Safari, and Opera
    }
  }

  .row {
    flex-shrink: 0;
  }

  .logo-bio {
    width: 100%;
    align-items: center;
    margin-bottom: 3vh;
    display: flex;
    flex-direction: column;
  }

  .logo-holder {
    display: flex;
    justify-content: center;
    overflow: hidden;
  }

  .collapsible-bio-container {
    margin-bottom: 0px;
  }
}
$bg-color: #0e1117;
$light-theme-text-color: #31333f;
$dark-theme-text-color: #fafafa;
$secondary-bg-color: #262730;

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

body {
  background-color: $bg-color;
  font-family: Calibri !important;
}

.container {
  margin: 0 20vw;
  margin-bottom: 100px;

  @media only screen and (max-width: 767px) {
    width: 100%;
    padding: 0 30px;
    margin: 0 0;
    margin-bottom: 100px;
  }
}

.feedback-buttons.hidden {
  display: none;
}

.feedback-buttons.show {
  display: block;
}

// loader start
.flexbox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 10px 0;
  > div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    overflow: hidden;
  }
  .text {
    margin-right: 10px;
  }
  .dot-loader {
    height: 5px;
    width: 5px;
    border-radius: 50%;
    background-color: #fff;
    position: relative;
    -webkit-animation: 1.2s grow ease-in-out infinite;
    animation: 1.2s grow ease-in-out infinite;
    &.dot-loader--2 {
      -webkit-animation: 1.2s grow ease-in-out infinite 0.15555s;
      animation: 1.2s grow ease-in-out infinite 0.15555s;
      margin: 0 5px;
    }
    &.dot-loader--3 {
      -webkit-animation: 1.2s grow ease-in-out infinite 0.3s;
      animation: 1.2s grow ease-in-out infinite 0.3s;
    }
  }
}

@-webkit-keyframes grow {
  0%,
  40%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes grow {
  0%,
  40%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

// loader end

.selector {
  margin-top: 20px;

  .image-holder {
    display: flex;
    margin: 20px -10px;
    .img {
      width: 33.33%;
      padding: 0 10px;
      display: flex;
      align-items: center;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}

.toggleSwitch {
  z-index: 100000;
  position: fixed;
  top: 10px;
  right: 20px;
}

.lightdark {
  color: $dark-theme-text-color;
  margin: 0 15px;
}

.dark {
  color: $dark-theme-text-color;
}

.feedbackbtn {
  width: 120px;
  margin: 0 10px;
  cursor: pointer;
  border-radius: 15px;
  height: 33px;
  background-color: transparent;
  margin-bottom: 10px;
}

.feedbackbtn a {
  text-decoration: none;
}

.feedbackbtn:hover {
  opacity: 0.8;
}

.btnlight a {
  color: $light-theme-text-color;
}

.btndark a {
  color: $dark-theme-text-color;
}

// NavBar
.nav-bar {
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  align-items: center;
  background-color: transparent;
  
  &.light {
    color: $light-theme-text-color;
  }
}

.nav-links {
  display: flex;
  gap: 30px;
  
  a { text-decoration: none; }

  @media (max-width: 768px) {
    display: none;
    gap: 10px;
  }

  // Expanded styles for small screens
  &.expanded {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 80px;
    right: 30px;
    width: 100px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    padding: 1rem;
  }
}

.navlinks-dark {
  border: 1px solid $dark-theme-text-color;
  background-color: $bg-color;
}

.navlinks-light {
  border: 1px solid $bg-color;
  background-color: $dark-theme-text-color;
}

.navtext-dark {
  color: $dark-theme-text-color;
}

.navtext-light {
  color: black;
}

.hamburger {
  display: none; // Initially hidden
  align-items: center;
  justify-content: center;
  font-size: 24px;
  cursor: pointer;
  padding: 10px;
  margin-bottom: 15px;

  @media (max-width: 768px) {
    display: flex;
  }
}

// dropdown animation
@media (max-width: 768px) {
  @keyframes dropdown {
    from { transform: scaleY(0); transform-origin: top; }
    to { transform: scaleY(1); transform-origin: top; }
  }

  @keyframes dropup {
    from { transform: scaleY(1); transform-origin: top; }
    to { transform: scaleY(0); transform-origin: top; }
  }

  .nav-links.expanded {
    animation: dropdown 0.3s ease forwards;
  }

  .nav-links.dropup {
    animation: dropup 0.3s ease forwards;
  }  
}
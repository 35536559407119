$dark-theme-bg-color: #0e1117;
$light-theme-text-color: #31333f;
$dark-theme-text-color: #fafafa;
$secondary-bg-color: #262730;

.page-div {
  max-width: 1000px;
  margin: 0 auto;
}

.selectOption {
  background-color: $secondary-bg-color;
  color: $dark-theme-text-color;
  width: 50%;
  height: 40px;
  border-radius: 5px;
  border: transparent;
  margin-top: 20px;
  padding: 0 10px;
}

.footer {
  position: fixed;
  text-align: center;
  width: 100%;
  height: 25px;
  bottom: 0;
  left: 0;
}

.copyright {
  font-size: 10px;
  margin: auto 0;
}

.commonPara {
  margin-top: 20px;
}

.commonPara li a {
  text-decoration: underline;
}

.btn, .mode-btn {
  width: 80px;
  height: 33px;
  background-color: transparent;
  color: $dark-theme-text-color;
  cursor: pointer;
  border-radius: 15px;
  margin-right: 10px;
  margin-bottom: 15px;
}

.mode-btn {
  width: 125px;
}

.hide-btn {
  width: 100px;
  height: 30px;
  background-color: $dark-theme-bg-color;
  opacity: 0.9;
  color: $dark-theme-text-color;
  cursor: pointer;
  border-radius: 15px;
}

.btn:hover{
  opacity: 0.8;
}

.function-btns-container {
  width: 100%;
  padding: 0;
  margin: 0;
}

.input-box-title {
  font-weight: 400;
  font-size: 24px;
  margin-bottom: 15px;
}

.function-btns {
  background-color: darken($dark-theme-bg-color, 10%);
  border: 1px solid $dark-theme-text-color;
  padding: 20px;
  padding-bottom: 5px;
  border-radius: 15px;
}

.function-btns-light {
  background-color: $dark-theme-text-color;
  border: 1px solid $light-theme-text-color;
}

.endbtn {
  width: 135px;
  height: 33px;
  background-color: $dark-theme-bg-color;
  cursor: pointer;
  border-radius: 15px;
}

.endbtn:hover {
  opacity: 0.8;
}

.btnlight {
  color: $light-theme-text-color;
  border: 1px solid $light-theme-text-color;
  background-color: transparent;
}

.btndark {
  color: $dark-theme-text-color;
  border: 1px solid $dark-theme-text-color;
}

.ant-select {
  width: 50%;
  min-width: 400px;
  margin-top: 20px;
  position: relative;
  @media screen and (max-width: 575px) {
    min-width: 100%;
  }
  .ant-select-selector {
    border-color: #fff !important;
    background-color: $secondary-bg-color !important;
    .ant-select-selection-item {
      color: #fff;
      display: flex;
      align-items: center;
    }
    @media screen and (max-width: 575px) {
      height: 40px !important;
    }
  }

  .ant-select-arrow {
    position: absolute;
    right: 15px;
    top: 50%;
    svg {
      color: #fff;
      transition: 0.2s all ease-in-out;
    }
  }
  &.ant-select-focused,
  &:hover {
    .ant-select-selector {
      border-color: #fff !important;
    }
  }
}

// light theme for select
.light {
  .ant-select {
    .ant-select-selector {
      background-color: #fff !important;
      border-color: $light-theme-text-color !important;
      .ant-select-selection-item {
        color: $light-theme-text-color;
      }
    }
    .ant-select-arrow {
      svg {
        color: $light-theme-text-color;
      }
    }
  }
}

.conversation-div {
  margin-bottom: 20vh;
}

.btndisabled {
  display: none;
}

.feedbackPara {
  line-height: 22px;
}

.hrline {
  opacity: 0.5;
}

.markdown-output {
  margin-bottom: 20px;

  p {
    margin: 1em 0;
  }

  ul, ol {
    padding-left: 20px;
    margin-bottom: 1em;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }

  th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }

  th {
    font-weight: 600;
  }
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  color: $dark-theme-text-color !important;
}

.light {
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    color: $light-theme-text-color !important;
  }
}

// image handling
.image-zoom-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.image-zoom-content {
  position: relative;
  max-height: 95vh;
  overflow-y: auto;
  width: auto;
}

.image-zoom-content img {
  max-width: 100%;
  max-height: 95vh;
  display: block;
  object-fit: contain;
}

.image-zoom-close {
  position: absolute;
  top: 8px;
  right: 8px;
  background: $dark-theme-text-color;
  color: $dark-theme-bg-color;
  border: 2px solid $dark-theme-bg-color;
  border-radius: 15px;
  text-align: center;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
}

.image-holder {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  width: 100%;
}

.image-holder-post {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  gap: 10px;
}

.img-wrapper {
  flex: 0 1 calc(30% - 20px);
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid $dark-theme-bg-color;
  border-radius: 17px;
  position: relative;
  margin: 10px;
  background-color: $dark-theme-text-color;
  min-height: 100px;

  // Position the image number label at the top of the image
  .image-number {
    position: absolute;
    top: 7px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0,0,0,0.5);
    color: $dark-theme-text-color;
    padding: 2px 8px;
    border-radius: 5px;
    z-index: 30;
    min-width: 85px;
    text-align: center;
  }

  img {
    display: block;
    max-width: 100%;
    height: auto;
    border-radius: 15px;
  }

  .img-cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $dark-theme-text-color;
    z-index: 10;
    border-radius: 15px;
  }

  .img-toggle {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 20;
  }

  @media screen and (max-width: 575px) {
    flex: auto;
  }
}

.optionSwitch {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 10px;
  margin-top: 15px;
}

.image-instruction {
  margin-top: 10px;
}

.image-numbers {
  text-align: center;
  margin-top: -15px;
}

.author-banner {
  display: flex;
  flex-direction: column;

  div {
    display: flex;
    align-items: center;

    a {
      display: inline-block;
    }

    img {
      cursor: pointer;
    }
  }
}

.image-zoom-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: $dark-theme-bg-color;
  background-color: $dark-theme-text-color;
  border: 1px solid $dark-theme-text-color;
  cursor: pointer;
  padding: 5px;
  font-size: 20px;
  font-weight: bold;
}

.image-zoom-nav, .image-zoom-close, .image-info {
  transition: opacity 0.5s ease;
  opacity: 1;
}

.faded {
  opacity: 0 !important;
}

.image-zoom-nav:hover, .image-zoom-close:hover {
  background-color: darken($dark-theme-text-color, 25%);
}

.image-zoom-nav.left {
  left: 10px;
  border-radius: 15px;
  border: 2px solid $dark-theme-bg-color;
}

.image-zoom-nav.right {
  right: 10px;
  border-radius: 15px;
  border: 2px solid $dark-theme-bg-color;
}

.image-info {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 5px 10px;
  border-radius: 10px;
  font-size: 16px;
}

.mcq-popup {
  position: relative;
  color: $dark-theme-text-color;
  background-color: $dark-theme-bg-color;
  border: 1px solid $dark-theme-text-color;
  padding: 40px 0;
  border-radius: 15px;
  width: 65%;

  @media screen and (max-width: 750px) {
    width: 80%;
  }

  .mcq-h2 {
    padding: 0 40px;
  }

  > div {
    margin-bottom: 30px;
    padding: 0 40px;

    .commonPara {
      margin-bottom: 15px;
    }

    .mcq-option {
      margin-bottom: 10px;

      .mcq-btn {
        display: block;
        width: 100%;
        text-align: left;
        padding: 10px;
        border-radius: 5px;
        margin-bottom: 5px;
        cursor: pointer;
      }

      .mcq-btn {
        background-color: $dark-theme-text-color;
        color: $dark-theme-bg-color;
      }
    }

    p:last-child {
      margin-top: 10px;
    }
  }
}

.mcq-image {
  cursor: pointer;
  max-width: 100%;
  margin-bottom: 15px;
}

// typing stuff
.typing-input {
  display: flex;
  align-items: center;
  background-color: transparent;
  border-radius: 15px;
  border: 1px solid $dark-theme-text-color;
  margin-bottom: 15px;
}

.typing-input-light {
  border: 1px solid $light-theme-text-color;
}

.margin-button {
  margin-top: 15px;
  margin-right: 15px;
}

.my-input {
  flex-grow: 1;
  padding: 0 10px;
  margin-left: 2px;
  background-color: transparent;
  color: $dark-theme-text-color;
  border: none;
  outline: none;

  &.light {
    color: $light-theme-text-color;
    background-color: transparent;
  }
}

.send-btn {
  padding: 10px 10px;
  border-top-right-radius: 14px;
  border-bottom-right-radius: 14px;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $dark-theme-text-color;

  &:hover {
    &:not(:disabled) {
      cursor: pointer;
      background-color: darken($dark-theme-text-color, 75%);
    }
  }

  &:disabled {
    cursor: default;
  }
}

.send-btn-light {
  color: $light-theme-text-color;

  &:hover {
    &:not(:disabled) {
      background-color: darken($dark-theme-text-color, 5%);
    }
  }
}

.mode-line {
  margin-top: 20px;
  margin-bottom: 0px;
  opacity: 0.5;
}

.response {
  margin-bottom: 20px;
}

.switch-btn, .timer-btn {
  width: 175px;
  height: 33px;
  border-radius: 15px;
  border: 1px solid $dark-theme-text-color;
  background-color: transparent;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  color: $dark-theme-text-color;
  cursor: pointer;
  margin-right: 10px;

  &.light {
    color: $light-theme-text-color;
    border: 1px solid $dark-theme-bg-color;
  }

  &:after {
    display: none;
  }

  &.hidden {
    display: none;
  }
}

.hidden {
  display: none;
}

.timer-btn {
  width: 100px;
}

// conversation stuff
.action-btns {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

.end-conversation-hidden {
  .switch-btn {
    display: none;
  }
}

.speed-slider {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;

  input[type="range"] {
    width: 255px;
    cursor: pointer;
  }

  @media screen and (max-width: 575px) {
    input[type="range"] {
      width: 50%;
    }
  }
}

.author-icon {
  max-height: 75px;
  margin-top: 15px;
}

.mobile-select {
  margin: 20px 0;
}

.mobile-select-btn {
  color: $dark-theme-text-color;
  border: 1px solid $dark-theme-text-color;
  background-color: transparent;
  width: 100%;
  border-radius: 10px;
  padding: 10px;
}

.mobile-select-btn-light {
  color: $light-theme-text-color;
  border: 1px solid $light-theme-text-color;
}

// difficulty mode
.difficulty-select {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.difficulty-option {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.dot {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: transparent;
  border: 2px solid $dark-theme-text-color;
}

.dot-selected {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: $dark-theme-text-color;
}

// Apply theme specific styles
.light .difficulty-option {
  .dot {
    border-color: $dark-theme-bg-color;
    background-color: $dark-theme-text-color;
    color: $light-theme-text-color;
  }

  .dot-selected {
    border-color: darken($dark-theme-text-color, 25%);
    background-color: $dark-theme-bg-color;
  }
}

// .sidebar-btns {
//   position: fixed;
//   right: 5px;
//   top: 50%;
//   background-color: #f8f9fa;
//   display: flex;
//   flex-direction: column;
//   border-radius: 10px;
// }

// .transition-button {
//   margin: 5px 10px;
// }